<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5"> Select Scopes</v-card-title>
        <v-divider class="ml-4 mr-5 mb-2" />
        <v-card-text>
            <v-data-table
                id="virtual-scroll-table"
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="wos"
                class="elevation-0"
                :mobile-breakpoint="0"
                fixed-header
                :height="$vuetify.breakpoint.mdAndDown ? '40vh' : '35vh'"
                :loading="loading"
            >
                <template v-slot:top>
                    <v-row
                        no-gutter
                        class="mt-0 mx-0 pb-0 align-center"
                        :style="{ 'background-color': '#eeeeee' }"
                    >
                        <v-col cols="9">
                            <h2 class="ml-4">Work Order Scopes</h2>
                            <v-spacer />
                        </v-col>
                    </v-row>
                </template>
                <!--ITEMS-->
                <template v-slot:[`item.workOrder`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0 text-capitalize">
                            {{ item.code }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.scope`]="{ item }">
                    <div class="d-flex justify-center">
                        <p class="my-0 text-capitalize">
                            {{ item.notes || '-' }}
                        </p>
                    </div>
                </template>
                <template v-slot:[`item.selected`]="{ item }">
                    <div class="d-flex justify-center">
                        <v-simple-checkbox
                            color="primary"
                            v-model="item.selected"
                        ></v-simple-checkbox>
                    </div>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="print" :loading="loading" rounded>
                Print
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'ScopesSelect',
    props: {
        wos: {
            type: Array,
            required: false,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'WORK ORDER',
                value: 'workOrder',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SCOPE',
                value: 'scope',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SELECTED',
                value: 'selected',
                align: 'center',
                sortable: false,
            },
        ],
    }),
    async mounted() {},
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        print() {
            const filteredWOs = this.wos.filter(w => w.selected)
            let scopes = ''
            if (filteredWOs.length > 0) {
                scopes = filteredWOs
                    .map(wo => wo.notes)
                    .filter(note => note !== undefined && note !== null)
                    .join(',')
            }
            this.$emit('print', scopes)
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table {
    border: 1px solid #eeeeee;
}
</style>
